import * as React from 'react';
import cn from 'classnames';
import Image from 'styleguide/components/Image/Image';
import { Taxon } from 'contexts/AppContext/types';
import Link from 'styleguide/components/Links/Link';
import UL, { LI } from 'styleguide/components/UL/UL';
import { IconArrowRight } from 'styleguide/icons';

interface Props {
  taxon?: Taxon;
  title?: string;
  subHeading?: string;
  listItems?: string[];
  imageLinkOverride?: string;
  actionLinkOverride?: string;
  actionLinkText?: string;
  constrainWidth?: boolean;
}

const ProductCard = ({
  title,
  subHeading,
  listItems,
  actionLinkOverride,
  actionLinkText,
  ...props
}: Props) => (
  <div
    className={cn(
      'flex flex-col items-start hover:shadow-lg',
      'rounded-lg border border-neutral-200 px-2 pt-2',
      props.constrainWidth ? 'max-w-[266px]' : '',
    )}
  >
    <div>
      <Link to={props.taxon ? props.taxon.pagePath : actionLinkOverride} color="black" underline="none">
        <Image
          width={250}
          height={198}
          title={props.taxon ? props.taxon.name : actionLinkText}
          alt={props.taxon ? props.taxon.image.alt : actionLinkText}
          url={props.taxon ? props.taxon.image.url : props.imageLinkOverride}
          className="object-cover"
        />
        <h3 className="paragraph-bold-desktop mt-4 mb-2 pl-4">{props.taxon ? props.taxon.name : title}</h3>
      </Link>
    </div>
    {!!subHeading && (
      <div className="paragraph-light-desktop mt-2 mb-4 pl-4">
        <span>{subHeading.split('$')[0]}</span>
        {subHeading.split('$')[1] && <span className="text-blue">{`$${subHeading.split('$')[1]} `}</span>}
      </div>
    )}
    {!!listItems && (
      <div className="pl-4">
        <UL>
          {listItems.map((item, index) => (
            <LI className="!mb-2 !font-hvThin !text-sm" iconSize="small" iconColor="orange" key={index}>
              {item}
            </LI>
          ))}
        </UL>
      </div>
    )}
    {!!actionLinkText && (
      <div className="mb-4 ml-4 mt-6 flex items-center">
        <Link
          className="mr-2 !font-hvMedium !text-sm"
          to={props.taxon ? props.taxon.permalink : actionLinkOverride}
          color="blue"
          underline="none"
        >
          {actionLinkText.replace('Print', 'Shop')}
        </Link>
        <IconArrowRight size="xxs" color="blue" />
      </div>
    )}
  </div>
);

export default ProductCard;
